


.clock_container{
    width: 100%;
    text-align: center;
    top: 50%;
}
.analog{
    position: relative;
    background-color: white;
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 10px;
}

.digital{
    font-size: 3vw;
    color: white;
    margin-bottom: 25px;
}

@media only screen and (max-width: 700px) {
.analog{
    display: none;
}

.digital{
    font-size: 7vw;
    color: white;
    margin-bottom: 25px;
}
}

.tick{
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    font-size: small;
    color: white;
}

#tick2{
    transform: rotate(30deg);
}
#tick3{
    transform: rotate(60deg);
}
#tick4{
    transform: rotate(90deg);
}
#tick5{
    transform: rotate(120deg);
}
#tick6{
    transform: rotate(150deg);
}
#tick7{
    transform: rotate(180deg);
}
#tick8{
    transform: rotate(210deg);
}
#tick9{
    transform: rotate(240deg);
}
#tick10{
    transform: rotate(270deg);
}
#tick11{
    transform: rotate(300deg);
}
#tick12{
    transform: rotate(330deg);
}
#tick13{
    transform: rotate(0deg);
}


.inner_clock{
    position: absolute;
    top: 50%;
    margin-left: 2.5%;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 95%;
    height: 95%;
    background-color: black;
}

.clock-hand-second{
    position: absolute;
    width: .2vw;
    height: 40%;
    border-radius: 5px;
    background-color: #B0B7BC;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
    transform-origin: bottom;

}

.clock-hand-minute{
    position: absolute;
    width: .4vw;
    height: 29%;
    border-radius: 5px;
    background-color: #0076B6;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
    transform-origin: bottom;

}

.clock_center{
    width: 7%;
    height: 7%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 47%;
    transform: translateY(-50%);
    border-radius: 50%;
}



