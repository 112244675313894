body{
    overflow: hidden;
    font-family:'Courier New', Courier, monospace;
}

#pawn5{
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background: radial-gradient(circle at 100px 100px,white, rgb(90, 82, 82));
}

#pawn7{
    height: 200px;
    width: 50px;
    border-radius: 50%;
    background: radial-gradient(circle at 100px 100px,white, rgb(90, 82, 82));
}

.opening-window{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    z-index: 10000000;
    max-height: 100vh;
    padding-top: 20px;
    padding-bottom: 50px;

}

.box{
    border-radius: 5px;
    position: relative;
    background-color: black;
    text-align: center;
    padding: 20px;
    height: fit-content;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.play-outer:before{
    content:"";
    background-color: transparent;
    border-bottom: solid 3.5px white;
    position: absolute;
    height: 100%;
    width: calc(100% + 3.5px);
    color: white;
    bottom: 0;
    left: 0;
    transform: scale(0,1);
    transform-origin: 100% 100%;
    transition: transform .25s;
}


.play-outer:hover .play-button:before{
    background-color: transparent;
    transform: scale(1,1);
    transform-origin: 100% 100%;
    border-radius: 5px;

}

.play-outer:hover .play-button:after{
    background-color: transparent;
    transform: scale(1,1);
    transform-origin: 0% 0%;
    border-radius: 5px;

}

.play-outer{
    border-radius: 5px;

    font-size: xx-large;
    position: relative;
    height: fit-content;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 12px;
    width: fit-content;
    background-color: black;
    color: #B0B7BC;
    cursor: pointer;
    margin: auto;
    margin-top:1vw;
    opacity: 1;
    transition: .25s;
}

.play-outer:hover{
color:white;
}

.play-outer::after{
    content:"";
    background-color: transparent;
    border-top: solid 3.5px white;
    position: absolute;
    height: 100%;
    width: calc(100% + 3.5px);
    color: white;
    bottom: 0;
    left: 0;
    transform: scale(0,1);
    transform-origin: 0% 0%;
    transition: transform .25s;
}



.play-outer:hover::after{
    transform: scale(1,1);
    transform-origin: 100% 0%;
    border-radius: 5px;

}

.play-outer:hover::before{
    transform: scale(1,1);
    transform-origin: 0% 100%;
    border-radius: 5px;

}

.play-button::after{
    content:"";
    background-color: transparent;
    border-left: solid 3.5px white;
    position: absolute;
    height: calc(100% + 3.5px);
    width: 100%;
    color: white;
    bottom: 0;
    left: 0;
    transform: scale(1,0);
    transform-origin: 0% 100%;
    transition: transform .25s;
}

.play-button::before{
    content:"";
    background-color: transparent;
    border-right: solid 3.5px white;
    position: absolute;
    height: calc(100% + 3.5px);
    width: 100%;
    color: white;
    bottom: 0;
    left: 0;
    transform: scale(1,0);
    transform-origin: 100% 0%;
    transition: transform .25s;
}


.logo1{
    width: 25vw;
    height: 25vw;
    margin-bottom: 20px;
}


.instructions1{
    color: #B0B7BC;
    transition: 0.25s;
    margin-left: 3vw;
    margin-right: 3vw;

}

.instructions1:hover{
    color: white;
    cursor: default;
}



.input1{
    outline: none;
    padding: 10px;
    border: white 5px solid;
    transition: 0.25s;
    color: #B0B7BC;
    text-align: center;
    border-radius: 5px;

}

.input1:hover{

    border: #B0B7BC 5px solid;

}
    
.input1:focus{
    border: #0076B6 5px solid;
    outline: none;
    color: black;
}

@media only screen and (max-width: 700px) {
    .play-outer:before{
        display: none;
    }
    .instructions1{
        color: white;
        font-size: 2.5vh;
    }

    .opening-window{
        position: absolute;
        width: 100%;
        height: 100%;
        min-height: 100%;
        background-color: rgb(0, 0, 0);
        z-index: 10000000;
        top: 0%;
        padding-top: 10%;
        padding-bottom: 40vh;
    
    }
    .play-outer{
        font-size: 5vh;
        position: relative;
        height: fit-content;
        padding-left: 5vw;
        padding-right: 5vw;
        padding-top: 2vw;
        padding-bottom: 2vw;
        width: fit-content;
        background-color: white;
        color: black;
        cursor: pointer;
        margin: auto;
        margin-top:5vw;
        opacity: 1;
        transition: .25s;
    }

    .input1:hover{
        border-width: 10px ;
        
    }

 
    
    .input1:focus{
        border: #0076B6 10px solid;
        outline: none;
        color: black;
    }

    .play-outer:hover{
    color:black;
    }

    .input1{
        outline: none;
        padding: 10px;
        border: white 10px solid;
        transition: 0.25s;
        color: #B0B7BC;
        text-align: center;
        font-size: 2.5vh;
    }
    
    .play-outer::after{
        display: none;

    }

    
    .play-button::after{
        display: none;

    }
    
    .play-button::before{
        display: none;

    }
    
    
    .logo1{
        width: 35vh;
        height: 35vh;
        margin-bottom: 25px;
        margin-top: 25px;
    }

}