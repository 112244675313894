.mini_square{
    width: 25%;
    position: relative;
    padding-top: 25%;
    display: inline-block;
}

.squarepic{
    width: 100%;
}

.pointstotal{
    width: 100%;
}

.board-row2{
    font-size: 0;
    
}