


.square{
    padding:0;
    position: relative;
    text-align: center;
    display: inline-block;
    width: 12.5%;
    padding-top: 12.5%;
    cursor: pointer;
}
#button-container{
    left: 2.5%;
    top: 0%;
    margin-bottom: 15%;
    background-color: transparent;
    height: 5vh;
}

.squarepic{
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-position: center;
    object-fit: cover;
}

.largepiece{
    position: absolute;
    width: 90%;
    height: 90%;
    z-index: -1;
    top: 5%;
    left: 5%;
    right: 0;
    bottom: 0;
    object-position: center;
    object-fit: contain;
    cursor: pointer;

}

.medpiece{
    position: absolute;
    width: 80%;
    height: 80%;
    z-index: -1;
    top: 10%;
    left: 10%;
    right: 0;
    bottom: 0;
    object-position: center;
    object-fit: contain;
    cursor: pointer;

}

.smallpiece{
    position: absolute;
    width: 65%;
    height: 65%;
    z-index: -1;
    top: 17.5%;
    left: 17.5%;
    right: 0;
    bottom: 0;
    object-position: center;
    object-fit: contain;
    cursor: pointer;

}

.board-row{
    width: 100%;
    height: fit-content;
    margin: 0;
    padding:0;
    overflow: auto;
    font-size: 0;
}

#center{
    position: absolute;
    display: inline-block;
    width: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 100vh;
    

}

.board-pad{
    height: 25px;
}

#game-board{
    border-radius: 5px;
}

#left{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    display: inline-block;
    width: 20%;
    margin-left: 2.5%;
    max-height: 100vh;
    
}

#right{
    position: absolute;
    display: inline-block;
    width: 20%;
    left: 75%;
    top: 50%;
    transform: translate(0, -50%);
    margin-left: 2.5%;
    max-height: 100vh;
    
}




.wpoints-display{
    width: 100%;
    padding-top: 4%;
    padding-bottom: 4%;
    color: black;
    background-color: white;
}

.bpoints-display{
    width: 100%;
    padding-top: 4%;
    padding-bottom: 4%;
    color: white;
    background-color: black;
}

.review{
    position: absolute;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    cursor: pointer;
    transition: .25s;
    z-index: 5;
    color: black;
    background-image: linear-gradient(to left, #B0B7BC 50%, #0076B6 50%);
    background-size: 202% 100%;
    background-position: 100% 0%;
    border-radius: 5px;

}

.review:hover{
    background-position: 0% 0%;
    color: white
}




.resume{
    position: absolute;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    cursor: pointer;
    transition: .25s;
    z-index: 5;
    color: black;
    background-image: linear-gradient(to left, #B0B7BC 50%, #0076B6 50%);
    background-size: 202% 100%;
    background-position: 100% 0%;
    border-radius: 5px;

}

.resume:hover{
    background-position: 0% 0%;
    color: white}



.game{
    background-color: black;
    height: 100%;
    width: 100vw;
    max-height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -100;
    overflow-y: auto;
}




@media only screen and (max-width: 700px) {
    #center{
        position: relative;
        display: inline-block;
        width: 100%;
        left: 50%;
        margin-left: 0%;
        z-index: 100;
        top:auto;
        margin-top: 18vh;
        margin-bottom: 0;
        transform: translate(-50%,0);
    }
    
    #left{
        position: relative;
        left: 0;
        top: auto;
        width: 100%;
        margin-left: 0;
        z-index: 50;
        margin-top: 100px;
        transform: translate(0,0);
        margin-bottom: 25px;

    }
    
    #right{
        margin-top: 25px;
        position:absolute;
        height: 10vh;
        width: 100%;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        margin-left: 0;
        z-index: 25;
        
    }

    #point1{
        top: 0;
        position: absolute;
        right: 0%;
        width: 40%;
        margin-right: 5%;
        padding-bottom: 25px;
    }


    #point2{
        top: 0;
        left: 0%;
        position: absolute;
        width: 40%;
        margin-left: 5%;
    }

    #button-container{
        position: relative;
        margin-bottom: 0;
        left: 0;
    }

    #slide1{
        position: relative;
        width: 90%;
        margin: auto;
        margin-top: 20px;
   
       }




    #clock1{
        display: inline-block;
     margin-top: 115vw;
     position: relative;
     transform: translate(0,0);
     text-align: center;
     width: 50%;

    }


    #clock2{
        display: inline-block;

        margin-top: 115vw;

     transform: translate(0,0);
        position: relative;
        width: 50%;
        text-align: center;

    }

    .board-pad{
        height: 0px;
    }

.resume{
    font-size: 4vw;
    width: 15%;
    background-image: linear-gradient(to left, white 50%, white 50%);
    color: black;
    background-size: 100% 100%;
    
}

.wpoints-display{
    font-size: 2.5vh;

}

.bpoints-display{
    font-size: 2.5vh;
}


.review{
    font-size: 2.5vh;
    width: fit-content;
    background-image: linear-gradient(to left, white 50%, white 50%);
    color: black;
    background-size: 100% 100%;
    padding: 2vh;

}

.resume{
    font-size: 2.5vh;
    width: fit-content;
    background-image: linear-gradient(to left, white 50%, white 50%);
    color: black;
    background-size: 100% 100%;
    padding: 2vh;


}


.resume:hover{
    color: black;
}

.review:hover{
    color: black;
}

  }