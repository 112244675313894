

.closing-page{
    position: absolute;
    width: 30%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: black;
    z-index: 10000000;
    border-radius: 5px;
    color: white;
}

#closing-message{
    font-family: 'Courier New', Courier, monospace;;
    font-size: 4vh;
    margin: 25px;
}


.review3{
    position: relative;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    cursor: pointer;
    transition: .25s;
    z-index: 5;
    color: black;
    background-image: linear-gradient(to left, #B0B7BC 50%, #0076B6 50%);
    background-size: 202% 100%;
    background-position: 100% 0%;
    border-radius: 5px;
    margin-bottom: 25px;

}

.review3:hover{
    background-position: 0% 0%;
    color: white
}


@media only screen and (max-width: 700px) {
   
    .closing-page{
        width: 70%;
     
    }

    .review3{
        font-size: 3vh;

        color: black;
        background-color: white;
        background-image: linear-gradient(to left, white 50%, white 50%);

    }
    
    .review3:hover{
        color: black;
        background-color: white;
        background-image: linear-gradient(to left, white 50%, white 50%);
        font-size: 3vh;

    }
    
}