.slider1{

    width: 100%;
    cursor: pointer;
    margin-bottom:15%;
    margin-top:0%;
}

input[type=range]{
    -webkit-appearance: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 300px;
    height: 6px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #0076B6;
    margin-top: -6px;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc;
}


@media only screen and (max-width: 700px) {
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #0076B6;
        margin-top: -12px;
    }

    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 12px;
        background: #ddd;
        border: none;
        border-radius: 6px;
    }
}